<template>
  <section>
    <div v-if="yes==true">
      <div>
        <VProgressCircular
          :size="90"
          width="3"
          color="primary"
          indeterminate
        />
      </div>
      <h3>Redirecting, Please Wait...</h3>
    </div>
    <div v-else>
      <img
        src="../assets/icons/confused-guy.svg"
        alt="Confused"
      >
      <h1>Are You Sure?</h1>
      <h2>You will be redirected to futurelaureates.com</h2>
      <div class="mt-4">
        <VBtn
          class="mw"
          depressed
          large
          round
          color="primary"
          @click="go()"
        >
          Yes
        </VBtn>
        <VBtn
          class="mw"
          depressed
          large
          round
          color="primary"
          @click="no()"
        >
          No
        </VBtn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      yes: false,
    };
  },
  methods: {
    go() {
      this.yes = true;
      setTimeout(() => {
        window.location = '//futurelaureates.com';
      }, 2000);
    },
    no() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/redirect';
</style>
